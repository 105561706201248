import Banner from './layouts/Banner'
import DrawerAppBar from './layouts/Drawer'
import NavBar from './layouts/NavBar'

function App() {
  return (
    <div>
      <DrawerAppBar />
    </div>
  )
}

export default App
