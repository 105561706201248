import React from 'react'
import { Button } from '@mui/material'
import styled from 'styled-components'

const NavBar = () => {
  const handleTwitterClick = () => {
    window.open('https://twitter.com/MyEx_Exposed', '_blank')
  }
  const handleTelegramClick = () => {
    window.open('https://t.me/+TSPsSGDeoEliMWNh', '_blank')
  }
  const handleChartClick = () => {
    // window.open(
    //   'https://pump.fun/8RFvdXmGHSVHxgvKMeMfhoj4RN3TnVg5dNqQjQFDcptj',
    //   '_blank'
    // )
  }
  return (
    <Content>
      <Button
        onClick={() => {
          handleTwitterClick()
        }}
        sx={{ color: 'white' }}
      >
        Twitter
      </Button>
      <Button
        onClick={() => {
          handleTelegramClick()
        }}
        sx={{ color: 'white' }}
      >
        Telegram
      </Button>
      <Button
        onClick={() => {
          handleChartClick()
        }}
        sx={{ color: 'white' }}
      >
        Chart
      </Button>
    </Content>
  )
}

export default NavBar

const Content = styled.div``
