import {
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@mui/material'
import React, { useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import styled from 'styled-components'
const data = [
  '[Name] once tried to water plants during a rainstorm.',
  'Did you know [Name] is a professional couch potato on weekends?',
  '[Name] thinks cereal is a soup.',
  "[Name]'s favorite sport? Remote control lifting!",
  'Watch out for [Name], the snack-stealing ninja.',
  '[Name] has a PhD in binge-watching TV shows.',
  'If confusion were an Olympic sport, [Name] would take gold.',
  '[Name] is known for their legendary mismatched socks.',
  'Ask [Name] about the time they got lost on an escalator.',
  "[Name]'s cooking could set off the smoke alarm... with a salad!",
  'Secretly, [Name] believes they’re a wizard at parallel parking.',
  '[Name] can trip over flat surfaces with impressive skill.',
  "If there's a wrong way to say a word, [Name] will find it.",
  '[Name] still thinks the moon follows their car.',
  '[Name] could forget their head if it wasn’t attached.',
  "[Name]'s been known to give pep talks to their plants.",
  "[Name] claims they're on a seafood diet: they see food, and they eat it!",
  'If you need advice on how to overthink, [Name] is your go-to.',
  '[Name] always has their phone on % battery.',
  "[Name]'s idea of multitasking is watching TV while scrolling through their phone.",
  '[Name] thinks making their bed is a workout.',
  "[Name]'s best dish? Ice cubes.",
  'If there was a late Olympics, [Name] would never show up on time.',
  '[Name] once locked themselves out of the car... while it was running.',
  '[Name] is a legend in their own lunchtime.',
  'Someday, [Name] will conquer their fear of speed bumps.',
  '[Name] has tried to screen shot their phone issues... on the same malfunctioning phone.',
  'If laziness were a sport, [Name] would participate... maybe tomorrow.',
  '[Name] once brought a spoon to a sword fight.',
  '[Name] treats a -minute walk like a major expedition.',
  '[Name] once cheered at the end of a commercial thinking it was the movie.',
  'If you need someone to press all the elevator buttons, [Name] is your person.',
  "[Name]'s idea of getting close to nature is watching Animal Planet.",
  'Ask [Name] about their world-famous burnt toast.',
  '[Name] tried to return a donut because it had a hole in it.',
  'If silly ideas were money, [Name] would be a billionaire.',
  '[Name] once tried to make grape juice by squishing fruit with their hands.',
  'Beware: [Name] can talk your ear off about absolutely nothing.',
  "[Name]'s superpower? They can fall asleep anywhere, anytime.",
  '[Name] once had a staring contest with a statue... and lost.',
  "[Name] thought 'The Hunger Games' was a cooking show.",
  'If you hear weird noises at night, don’t worry. It’s just [Name] trying to cook.',
  '[Name] is still waiting for their Hogwarts letter.',
  'If daydreaming were a job, [Name] would be rich!',
  '[Name] is the reason the mute button was invented.',
  'Guess who tried to use the TV remote to make a phone call? [Name]!',
  '[Name] thinks they’re a great singer. Shower disagrees.',
  '[Name] could write a book on how to clumsily fall up stairs.',
  'Did you know [Name] once went to a drive-thru on a bike?',
  '[Name] is the master of doing absolutely nothing all day.',
  '[Name] once had a battle of wits but was unarmed.',
  "Looking for lost items? [Name]'s probably sitting on them.",
  'If procrastination were a job, [Name] would apply later.',
  '[Name] can burn water.',
  "If you can't find [Name], check the nearest candy store.",
  '[Name] thinks sushi is just underwater sandwiches.',
  '[Name] would be late to their own surprise party.',
  '[Name] once called tech support for a power outage.',
  "[Name]'s dance moves could scare the stars out of the sky.",
  'Ask [Name] about the time they forgot their own phone number.',
  '[Name] might win an award for most alarms set.',
  'If there were a reality show for daydreamers, [Name] would be the star.',
  "If life gives [Name] lemons, they're probably out of sugar.",
  'If [Name] had a superpower, it would be invisibility in social situations.',
  'Watch [Name] mispronounce every menu item at a restaurant!',
  "[Name]'s parties are legendary... for the early bedtime.",
  "[Name] is still trying to find the 'any' key on their keyboard.",
  'Did you know [Name] once tried to Google their own brain?',
  '[Name] is as spontaneous as a TV rerun.',
  'If confusion paid, [Name] would be a millionaire.',
  "Don't ask [Name] for directions unless you have extra vacation days.",
  '[Name] would lose a debate with a parrot.',
  'Looking for the remote? [Name] probably sat on it again.',
  'If you want to keep a secret from [Name], put it in a book.',
  'Watch [Name] use a map... and get more lost.',
  'Ask [Name] about the time they tried to bake cookies with salt instead of sugar.',
  '[Name] treats their car like a trash can on wheels.',
  "If [Name] were a spice, they'd be flour.",
  '[Name] once looked for glasses without realizing they were wearing them.',
  'If misplacing things were a talent, [Name] would be a star.',
  'Watch [Name] try to use chopsticks... and eat rice with a spoon instead.',
  "[Name]'s favorite workout routine is jumping to conclusions.",
  "[Name] thinks they're a wine connoisseur because they like grape juice.",
  'If you want to hear a good joke, just ask [Name] to tell a serious story.',
  'If there’s a wrong way to use technology, [Name] will find it.',
  'Did you know [Name] once tried to scroll down a paper book?',
  "[Name]'s cooking tip: smoke detector as a timer.",
  "Watch [Name] mix up 'left' and 'right' every single time.",
  "[Name]'s been banned from cooking after the banana bread incident.",
  'If being awkward were a competition, [Name] would have spectators.',
  '[Name] once tried to solve an algebra problem with a calculator... during a history exam.',
  "[Name]'s most effective diet? Cooking their own food.",
  'If you need to laugh, just watch [Name] park a car.',
  '[Name] could get lost on a straight path.',
  "[Name]'s fashion sense is like a 'before' picture.",
  "If you hear a sigh, it's just [Name] opening their exercise app.",
  "[Name]'s survival strategy? Avoid cooking at all costs.",
  'Watch [Name] read an upside-down map.',
  "If [Name] were a phone app, they'd need an update every day.",
  "[Name]'s idea of an adventure is trying a new flavor of ice cream.",

  '[Name] once tried to pause a book because they needed a snack break.',
  'If you need a laugh, just ask [Name] to cook dinner.',
  "[Name]'s idea of a good time is watching paint dry.",
  'Legend says [Name] can get lost in their own backyard.',
  '[Name] thinks WiFi is some kind of magical airwave.',
  'Did you hear? [Name] once tried to order pizza from an ATM.',
  "[Name]'s favorite karaoke song? Anything sung silently.",
  'If you want a comedy show, watch [Name] try to fold a fitted sheet.',
  '[Name] once called a locksmith to get them out of an unlocked car.',
  'Ask [Name] about their record for most naps taken in a day.',
  "If talking to plants made them grow, [Name]'s garden would be a jungle.",
  'If absent-mindedness was a college course, [Name] would be the professor.',
  '[Name] once went to a pet store to find a unicorn.',
  '[Name] thought a quarterback was a refund.',
  '[Name] might be the only person to trip over wireless Internet.',
  'If being goofy was a crime, [Name] would be on the most wanted list.',
  'When [Name] makes lemonade, it somehow turns into limeade.',
  "[Name] once watched 'Frozen' and looked for the ice cream.",
  'Did you know [Name] once tried to rewind a digital book?',
  'Beware: [Name] can turn any diet into a dessert fest.',
  'If [Name] was a chef, their specialty would be cereal.',
  '[Name] thinks starbucks is a bank in space.',
  'If you need help sleeping, just ask [Name] to explain quantum physics.',
  "If there's a way to mess up selfies, [Name] has mastered it.",
  '[Name] once got a sunburn at night.',
  "If there's a 'push' sign, [Name] will pull first... every time.",
  "[Name]'s favorite workout is lifting pizza to their mouth.",
  'If you hear about a traffic jam, [Name] is probably driving the other way.',
  'If confusing left and right was an art, [Name] would be Picasso.',
  'If you want to keep something safe, just let [Name] forget where it is.',
  "[Name]'s idea of high tech is a flashlight with batteries.",
  '[Name] once tried to sell a free coupon.',
  'When life gives [Name] lemons, they squirt them in their eye.',
  "[Name] thinks 'manual' is a person.",
  'Did you know [Name] tried to shampoo their hair with soap?',
  "If there's a way to wear clothes inside out, [Name] knows it.",
  "[Name]'s most successful recipe is ice.",
  'Watch [Name] use a map—it’s better than a comedy show.',
  'If cluelessness was a sport, [Name] would be world champion.',
  'When [Name] says they’re multitasking, it means they’re losing things at a faster rate.',
  'If getting lost was a superpower, [Name] would be a superhero.',
  '[Name] once tried to make a sandwich with the phone.',
  'Beware: [Name] thinks salsa dancing is about chips and dip.',
  'If [Name] were a film genre, they’d be an unpredictable comedy.',
  "[Name]'s cooking is so bad, even the smoke detector cheers on.",
  'If you ask [Name] for directions, you’ll need a vacation afterwards.',
  '[Name] thinks Netflix and chill means frozen TV dinners.',
  'If you want to go on an adventure, let [Name] pick the destination.',
  "If [Name] wrote a book, it would be titled 'How to Trip Over Nothing.'",
  'If there’s a wrong pipe to sing in the shower, [Name] will find it.',
  '[Name] once searched for glasses they were already wearing.',
  'If there were an award for being late, [Name] wouldn’t show up to accept it.',
  'If you need help breaking a new gadget, [Name] is on speed dial.',
  "If [Name] was a TV show, they’d be a mystery—no one knows what they'll do next.",
  '[Name] thinks their GPS is arguing with them.',
  'Watch [Name] try to use a smartphone with gloves on.',
  'If [Name] were an app, they’d be the one that crashes all the time.',
  'Did you know [Name] once tried to bake cookies in the microwave?',
  'If [Name] was a vehicle, they’d be a unicycle—fun but unpredictable.',
  'When [Name] cooks, the emergency services are on standby.',
  'If being adorable was a fault, [Name] would need major repairs.',
  "If you need a random fact, [Name]'s got a thousand—none of them true.",
  'If you let [Name] plan your trip, you might end up at the grocery store.',
  'If [Name] were a superhero, their power would be making everyone smile.',
  '[Name] thinks algebra is a type of pasta.',
  'Watch out! [Name] is cooking tonight. Might want to order a backup dinner.',
  "[Name]'s best magic trick? Making their keys disappear.",
  'When [Name] says they know a shortcut, prepare for a three-hour tour.',
  'If [Name] was a detective, they’d probably arrest the mirror for spying.',
  'If you need a good story, just ask [Name] about their last shopping trip.',
  "If you want a quiet evening, don't invite [Name] to play board games.",
  'If there’s a joke to crack, [Name] will be the first to laugh... at their own joke.',
  'When [Name] plays hide and seek, you won’t find them—they forgot where they hid.',
  "If you want to stay young at heart, just follow [Name]'s daily routine.",
  'If you want to lose a game of chess, play against [Name].',
  'If [Name] were a book, they’d be an open one—easy to read and full of surprises.',
  'If life is a highway, [Name] is the one who took the scenic route by mistake.',
  'If you want to see a disaster in the kitchen, just watch [Name] make salad.',
  'If there’s a button to push, [Name] will push it... multiple times.',
  'If you want to know how not to do DIY, just ask [Name].',
  'If [Name] were a spice, they’d be cinnamon—sweet but with unexpected kicks.',
  'If you ask [Name] for help with tech, you’ll end up more confused.',
  'If [Name] was in charge of weather, it’d rain every time they planned an outing.',
  'If you need comic relief, just ask [Name] to tell you about their day.',
  'If there’s a way to make a simple task complicated, [Name] knows it.',
  'If you want an unconventional opinion, just ask [Name].',
  'When [Name] goes camping, even the bears hide their food.',
  'If you think you’re bad at sports, you haven’t seen [Name] play tennis.',
  'If [Name] were music, they’d be jazz—impossible to predict and always entertaining.',
  'If you want to learn patience, try teaching [Name] how to use a new gadget.',
  'If [Name] was a cat, they’d be curious about everything... and scared of vacuum cleaners.',
  'If [Name] joined a choir, they’d be asked to lip sync.',
  'If you need a smile, just listen to [Name] explain technology.',
  "If you’re looking for a good mystery, check out [Name]'s cooking.",
  'If you need to feel better about your cooking, you haven’t tasted [Name]’s.',
  'If you want a chaotic adventure, follow [Name] on a normal day.',
  'If you think you lose things often, [Name] turned it into an art form.',
  'If [Name] were a weather forecast, they’d be a whirlwind of surprises.',
  'If you want to feel like a tech genius, just help [Name] set up their email.',
  'If [Name] were a dance move, they’d be the ‘sprinkler’—unexpected and everywhere at once.',
]
const Traker = () => {
  const [name, setName] = useState(null)
  const [result, setResult] = useState(null)
  const track = () => {
    const randomIndex = Math.floor(Math.random() * data.length)
    const randomSentence = data[randomIndex]
    const replacedSentence = randomSentence.replace('[Name]', name)

    setResult(replacedSentence)
    setName('')
  }
  return (
    <Content>
      <InputBox>
        <InputField>
          <FormControl variant='filled' sx={{ width: '100%', height: '100%' }}>
            <InputLabel htmlFor='component-simple'>Tracking Name</InputLabel>
            <FilledInput
              id='component-simple'
              sx={{ width: '100%', height: '100%' }}
              onChange={(e) => {
                setResult(null)
                setName(e.target.value)
              }}
              value={name}
            />
            <FormHelperText id='component-helper-text'>
              MULTIPLE TRACKING NUMBERS |NEED HELP?
            </FormHelperText>
          </FormControl>
        </InputField>
        <InputButton
          onClick={() => {
            setResult(null)
            if (name !== null && name !== '') {
              track()
            }
          }}
        >
          <p id='centered-text'>TRACK</p>
        </InputButton>
      </InputBox>
      {result !== null && result !== '' ? (
        <>
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              result,
              1000, // wait 1s before replacing "Mice" with "Hamsters"result,
            ]}
            wrapper='span'
            speed={50}
            // style={{ fontSize: '1em', display: 'inline-block' }}
            repeat={Infinity}
          />
        </>
      ) : (
        ' '
      )}
    </Content>
  )
}

export default Traker

const Content = styled.div`
  width: 30%;
  margin: auto;
  margin-top: 40px;
  @media only screen and (max-width: 600px) {
    width: 98%;
    margin: auto;
    margin-top: 40px;
  }
`
const InputBox = styled.div`
  display: flex;
  height: 80px;
`
const InputField = styled.div`
  width: 75%;
  height: 60px;
  /* background: red; */
`
const InputButton = styled.div`
  width: 25%;
  /* height: 100%; */
  /* background: black; */
  background-color: #ff6200;
  border-radius: 0;
  font-size: 1.1875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.11px;
  line-height: 12px;
  /* display: inline-block; */
  text-align: center;
  color: white;
  display: flex;
  justify-content: center; /* Horizontal alignment */
  align-items: center; /* Vertical alignment */
  height: 60px; /* Set a height for the container if needed */
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    transition: all 0.2s;
  }
`
