import React from 'react'
import styled from 'styled-components'
import BannerImage from '../assets/HOME PAGE FULL IMAGE.png'
import LeftIcon from '../assets/RATE & TRANSIT TIMES ICON.png'
import CenterIcon from '../assets/TRACK ICON MIDDLE ).png'
import RightIcon from '../assets/SHIP ICON.png'
const Banner = () => {
  return (
    <Content>
      <BannerImg src={BannerImage} alt='' srcset='' />
      <CenteredContainer>
        <HeadLine>Where you meet Ex</HeadLine>
        <Rectangles>
          <div
            style={{
              width: '33%',
              background: '#FAFAFA',
            }}
          >
            <Icon src={LeftIcon} />
          </div>
          <div
            style={{
              width: '33%',
              background: '#4D148C',
              transform: 'scale(1.15)',
            }}
          >
            <Icon src={CenterIcon} />
          </div>
          <div
            style={{
              width: '33%',
              background: '#FAFAFA',
            }}
          >
            <Icon src={RightIcon} />
          </div>
        </Rectangles>
      </CenteredContainer>
    </Content>
  )
}
export default Banner

const Content = styled.div`
  position: relative;
  /* left: -25px; */
  width: 100vw;
`

const BannerImg = styled.img`
  width: 100%;
  margin: auto;
  height: 60vh;
  /* margin: 10px 10px 0 0; */
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
    height: 30vh;
  }
`

const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* font-size: 24px; */
  text-align: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
    height: 40vh;
  }
`
const HeadLine = styled.div`
  font-size: 2.75rem;
  margin-top: 50px;
`
const Rectangles = styled.div`
  display: flex;
  width: 60%;
  margin: auto;
  margin-top: 60px;
`

const Icon = styled.img`
  max-width: 100%;
  margin: auto;
`
