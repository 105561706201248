import React from 'react'
import styled from 'styled-components'

const Token = () => {
  return (
    <Content>
      <Text>CA: </Text>
    </Content>
  )
}

export default Token

const Content = styled.div`
  display: flex;
  color: white;
  margin: 30px 0;
`
const Text = styled.div`
  margin: auto;
  padding: 1.5% 0% 1.5% 0%;
  background-color: #4d148c;
  border-style: solid;
  border-width: 0 0 0 0;
  border-color: rgb(229, 231, 235);
  border-radius: 10px 10px 10px 10px;
  width: 40vw;
  text-align: center;
  font-size: 21px;
  overflow-wrap: break-word;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`
