import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import NavBar from './NavBar'
import Banner from './Banner'
import styled from 'styled-components'
import Image from '../assets/new logo.png'
import Traker from './Traker'
import Token from './Token'
const drawerWidth = 240
const navItems = ['Twitter', 'Telegram', 'Chart']

function DrawerAppBar(props) {
  const windows = props.window
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleTwitterClick = () => {
    window.open('https://twitter.com/MyEx_Exposed', '_blank')
  }
  const handleTelegramClick = () => {
    window.open('https://t.me/+TSPsSGDeoEliMWNh', '_blank')
  }
  const handleChartClick = () => {
    //  window.open(
    //    'https://pump.fun/8RFvdXmGHSVHxgvKMeMfhoj4RN3TnVg5dNqQjQFDcptj',
    //    '_blank'
    //  )
  }
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        <Logo src={Image} />
      </Typography>
      <Divider />
      <List>
        <ListItem
          key={'Twitter'}
          onClick={() => {
            handleTwitterClick()
          }}
          disablePadding
        >
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Twitter'} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={'Telegram'}
          onClick={() => {
            handleTelegramClick()
          }}
          disablePadding
        >
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Telegram'} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={'Chart'}
          onClick={() => {
            handleChartClick()
          }}
          disablePadding
        >
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Chart'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  const container =
    windows !== undefined ? () => windows().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component='nav' sx={{ background: '#4d148c' }}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Logo src={Image} />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <NavBar />
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component='main'>
        <Toolbar />
        <Banner />
        <Traker />
        <Token />
      </Box>
    </Box>
  )
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
}

export default DrawerAppBar
const Logo = styled.img`
  /* position: fixed; */
  width: 150px;
  height: 90px;
`
